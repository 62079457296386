import { useMonkTheme } from '@monkvision/common';
import { Button, Icon } from '@monkvision/common-ui-web';
import { MonkLanguage } from '@monkvision/types';
import { useTranslation } from 'react-i18next';
import { styles } from './TabsSwitch.styles';

export enum TabMode {
  INTERIOR = 'interior',
  EXTERIOR = 'exterior',
}

export interface TabsSwitchProps {
  lang?: MonkLanguage;
  mode?: TabMode;
  onTabChange?: (mode: TabMode) => void;
}

export function TabsSwitch({ lang, mode, onTabChange = () => {} }: TabsSwitchProps) {
  const { palette } = useMonkTheme();
  const { t } = useTranslation('translation', { lng: lang });

  return (
    <div style={styles['container']}>
      <div style={styles['tabs']}>
        <Button
          onClick={() => onTabChange(TabMode.EXTERIOR)}
          variant='outline'
          primaryColor={palette.secondary.xlight}
          secondaryColor={palette.surface.light}
          style={{
            ...styles['exteriorButton'],
            backgroundColor: mode === TabMode.EXTERIOR ? palette.background.dark : '',
          }}
        >
          {mode === TabMode.EXTERIOR && <Icon style={styles['icon']} icon='check' />}
          {t('damageManipulator.exterior')}
        </Button>
        <Button
          onClick={() => onTabChange(TabMode.INTERIOR)}
          variant='outline'
          primaryColor={palette.secondary.xlight}
          secondaryColor={palette.surface.light}
          style={{
            ...styles['interiorButton'],
            backgroundColor: mode === TabMode.INTERIOR ? palette.background.dark : '',
          }}
        >
          {mode === TabMode.INTERIOR && <Icon style={styles['icon']} icon='check' />}
          {t('damageManipulator.interiorTire')}
        </Button>
      </div>
    </div>
  );
}
