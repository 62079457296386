import { Styles } from '@monkvision/types';

const SMALL_SCREEN__BREAKPOINT_PX = 650;
export const VEHICLE360_WIDTH_PX = 500;
const HEADER_HEIGHT_PX = 130;

export const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    width: '100%',
    height: '100%',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  vehicle360: {
    width: `${VEHICLE360_WIDTH_PX}px`,
    position: 'absolute',
    padding: '30px 35px 0 35px',
  },
  vehicle360SmallScreen: {
    __media: { maxWidth: SMALL_SCREEN__BREAKPOINT_PX },
  },
  gallery: {
    width: `calc(100% - ${VEHICLE360_WIDTH_PX}px)`,
    position: 'absolute',
    height: `calc(100% - ${HEADER_HEIGHT_PX}px)`,
    overflow: 'auto',
    right: 0,
    paddingRight: '20px',
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menu: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '35px 55px 10px 55px',
  },
  switchButton: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center',
  },
  generatePDFContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
};
