import { useTranslation } from 'react-i18next';
import { useObjectTranslation } from '@monkvision/common';
import { Icon, Button } from '@monkvision/common-ui-web';
import {
  ImageDetailedViewOverlayProps,
  useRetakeOverlay,
  useImageDetailedViewOverlayStyles,
  useImageLabelIcon,
} from './hooks';

export function ImageDetailedViewOverlay(props: ImageDetailedViewOverlayProps) {
  const { t } = useTranslation();
  const { tObj } = useObjectTranslation();
  const labelIcon = useImageLabelIcon(props);
  const retakeOverlay = useRetakeOverlay(props);
  const {
    mainContainerStyle,
    overlayDisplayStyle,
    complianceContainerStyle,
    complianceMessageContainerStyle,
    complianceIcon,
    complianceRetakeButton,
    complianceMessageStyle,
    complianceTitleStyle,
    complianceDescriptionStyle,
    imageLabelStyle,
    imageLabelIcon,
  } = useImageDetailedViewOverlayStyles(props);

  // useEffect(() => {
  //   console.log(props.image.renderedOutputs);
  // }, []);

  return (
    <div style={mainContainerStyle}>
      <div style={overlayDisplayStyle}>
        {!props.reportMode && (
          <div
            style={{
              ...complianceContainerStyle,
              visibility: props.reportMode ? 'hidden' : 'visible',
            }}
          >
            <div style={complianceMessageContainerStyle}>
              <Icon
                icon={retakeOverlay.icon}
                primaryColor={retakeOverlay.iconColor}
                size={complianceIcon.size}
              />
              <div style={complianceMessageStyle}>
                <div style={complianceTitleStyle}>{retakeOverlay.title}</div>
                <div style={complianceDescriptionStyle}>{retakeOverlay.description}</div>
              </div>
            </div>
            <Button
              style={complianceRetakeButton.style}
              size={complianceRetakeButton.size}
              primaryColor={retakeOverlay.buttonColor}
              onClick={props.onRetake}
            >
              {t('retake')}
            </Button>
          </div>
        )}
        {props.image.label && (
          <div style={imageLabelStyle}>
            {labelIcon && (
              <Icon
                icon={labelIcon.icon}
                primaryColor={labelIcon.primaryColor}
                style={imageLabelIcon.style}
                size={imageLabelIcon.size}
              />
            )}
            {tObj(props.image.label)}
          </div>
        )}
      </div>
    </div>
  );
}
