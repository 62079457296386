import {
  BoundingBox,
  DamageType,
  Image as MonkImage,
  PixelDimensions,
  Polygon,
} from '@monkvision/types';
import { changeAlpha } from '@monkvision/common';
import { DAMAGE_COLORS } from './types';

const RESIZE_RATIO = 0.25;
const IMAGE_QUALITY = 0.5;
const REFERENCE_PHOTO_PART_BADGE_WIDTH_RATIO = 0.04;
const REFERENCE_PHOTO_PART_BADGE_FONT_SIZE_WIDTH_RATIO = 0.05;
const CROPPED_PHOTO_FONT_SIZE_PX = 20;
const CROPPED_PHOTO_MIN_WIDTH_PX = 300;

export interface ResizedImage extends MonkImage {
  resizedUrl: string;
  resizedWidth: number;
  resizedHeight: number;
}

export interface IndexedBoundingBox {
  id: number;
  boundingBox: BoundingBox;
}

export interface CroppedPhotoDamage {
  type: DamageType;
  boundingBox: BoundingBox;
  polygons: Polygon[];
}

function mergeBoundingBoxes(bboxes: BoundingBox[]): BoundingBox {
  if (bboxes.length === 0) {
    return { xMin: 0, yMin: 0, width: 0, height: 0 };
  }
  let xMin = Infinity;
  let yMin = Infinity;
  let xMax = -Infinity;
  let yMax = -Infinity;
  bboxes.forEach((bbox) => {
    xMin = Math.min(xMin, bbox.xMin);
    yMin = Math.min(yMin, bbox.yMin);
    xMax = Math.max(xMax, bbox.xMin + bbox.width);
    yMax = Math.max(yMax, bbox.yMin + bbox.height);
  });
  return { xMin, yMin, width: xMax - xMin, height: yMax - yMin };
}

function padBoundingBox(bbox: BoundingBox, padding: number): BoundingBox {
  return {
    xMin: bbox.xMin - padding,
    yMin: bbox.yMin - padding,
    width: bbox.width + 2 * padding,
    height: bbox.height + 2 * padding,
  };
}

function createContext({ width, height }: PixelDimensions): {
  ctx: CanvasRenderingContext2D;
  cleanup: () => void;
} {
  const canvas = document.createElement('canvas');
  canvas.style.display = 'none';
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('Unable to generate PDF because the image processing canvas context is null.');
  }
  const cleanup = () => {
    canvas.remove();
  };
  return { ctx, cleanup };
}

function downloadImage(src: string): Promise<HTMLImageElement> {
  const image = new Image();
  return new Promise((resolve) => {
    image.onload = () => {
      resolve(image);
    };
    image.crossOrigin = 'anonymous';
    image.src = src;
  });
}

function convertCanvasToBlob(canvas: HTMLCanvasElement): Promise<Blob> {
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          reject(
            new Error(
              'Unable to generate PDF because image processing canvas toBlob function returned null.',
            ),
          );
        } else {
          resolve(blob);
        }
      },
      undefined,
      IMAGE_QUALITY,
    );
  });
}

export async function resizeImage(image: MonkImage): Promise<ResizedImage> {
  const resizedWidth = image.width * RESIZE_RATIO;
  const resizedHeight = image.height * RESIZE_RATIO;
  const { ctx, cleanup } = createContext({ width: resizedWidth, height: resizedHeight });
  const htmlImage = await downloadImage(image.path);
  ctx.drawImage(htmlImage, 0, 0, resizedWidth, resizedHeight);
  const blob = await convertCanvasToBlob(ctx.canvas);
  const resizedUrl = URL.createObjectURL(blob);
  cleanup();
  return { ...image, resizedUrl, resizedWidth, resizedHeight };
}

export async function drawSideReferencePhotoImage(
  image: ResizedImage,
  boundingBoxes: IndexedBoundingBox[],
): Promise<string> {
  const { ctx, cleanup } = createContext({
    width: image.resizedWidth,
    height: image.resizedHeight,
  });
  ctx.fillStyle = '#EDEDED';
  ctx.font = `${
    image.resizedWidth * REFERENCE_PHOTO_PART_BADGE_FONT_SIZE_WIDTH_RATIO
  }px sans-serif`;
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  const referenceImage = await downloadImage(image.resizedUrl);
  ctx.drawImage(referenceImage, 0, 0, image.resizedWidth, image.resizedHeight);

  boundingBoxes.forEach((bbox) => {
    ctx.beginPath();
    const x = (bbox.boundingBox.xMin + bbox.boundingBox.width / 2) * RESIZE_RATIO;
    const y = (bbox.boundingBox.yMin + bbox.boundingBox.height / 2) * RESIZE_RATIO;
    ctx.fillStyle = '#EDEDED';
    ctx.moveTo(x + image.resizedWidth * REFERENCE_PHOTO_PART_BADGE_WIDTH_RATIO, y);
    ctx.arc(x, y, image.resizedWidth * REFERENCE_PHOTO_PART_BADGE_WIDTH_RATIO, 0, 2 * Math.PI);
    ctx.fill();

    ctx.fillStyle = '#212121';
    ctx.moveTo(x + image.resizedWidth * REFERENCE_PHOTO_PART_BADGE_WIDTH_RATIO, y);
    ctx.fillText(bbox.id.toString(), x, y);
    ctx.closePath();
  });

  const blob = await convertCanvasToBlob(ctx.canvas);
  cleanup();
  return URL.createObjectURL(blob);
}

export async function drawCroppedPhotoImage(
  image: ResizedImage,
  damages: CroppedPhotoDamage[],
): Promise<string> {
  const totalBbox = padBoundingBox(
    mergeBoundingBoxes(damages.map(({ boundingBox }) => boundingBox)),
    20,
  );
  const totalBboxMaxSize = Math.max(totalBbox.width, totalBbox.height);
  const croppedPhotoSize = Math.max(totalBboxMaxSize, CROPPED_PHOTO_MIN_WIDTH_PX);

  totalBbox.xMin -= (croppedPhotoSize - totalBbox.width) / 2;
  totalBbox.yMin -= (croppedPhotoSize - totalBbox.height) / 2;
  totalBbox.width = croppedPhotoSize;
  totalBbox.height = croppedPhotoSize;

  const { ctx, cleanup } = createContext({
    width: totalBbox.width,
    height: totalBbox.height,
  });
  ctx.font = `bold ${CROPPED_PHOTO_FONT_SIZE_PX}px sans-serif`;
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  const baseImage = await downloadImage(image.path);
  ctx.drawImage(
    baseImage,
    totalBbox.xMin,
    totalBbox.yMin,
    totalBbox.width,
    totalBbox.height,
    0,
    0,
    totalBbox.width,
    totalBbox.height,
  );

  damages.forEach(({ type, polygons }) => {
    ctx.fillStyle = changeAlpha(DAMAGE_COLORS[type], 0.5);
    ctx.strokeStyle = DAMAGE_COLORS[type];
    ctx.lineWidth = 2;

    polygons.forEach((polygon) => {
      ctx.beginPath();
      polygon.forEach(([originalX, originalY], index) => {
        const x = originalX - totalBbox.xMin;
        const y = originalY - totalBbox.yMin;
        if (index === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }
      });
      ctx.closePath();
      ctx.fill();
      ctx.stroke();
    });
  });

  const blob = await convertCanvasToBlob(ctx.canvas);
  cleanup();
  return URL.createObjectURL(blob);
}
