import { useMonkTheme } from '@monkvision/common';
import { Button } from '@monkvision/common-ui-web';
import { styles } from './DoneButton.styles';

export interface DoneButtonProps {
  children: string;
  onConfirm: () => void;
}

export function DoneButton({ children, onConfirm }: DoneButtonProps) {
  const { palette } = useMonkTheme();

  return (
    <div style={styles['container']}>
      <Button
        style={{ ...styles['doneBtn'], backgroundColor: palette.text.black }}
        variant='outline'
        primaryColor='text-white'
        onClick={onConfirm}
      >
        {children}
      </Button>
    </div>
  );
}
