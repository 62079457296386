import { useMonkAppState } from '@monkvision/common';
import { useMemo } from 'react';
import { InspectionReport } from '../../components/InspectionReport';
import styles from './InspectionReportPage.module.css';

export function InspectionReportPage() {
  const { config, authToken, inspectionId, getCurrentSights } = useMonkAppState({
    requireInspection: true,
  });

  const apiConfig = {
    authToken,
    apiDomain: config.apiDomain,
    thumbnailDomain: config.thumbnailDomain,
  };

  const currentSights = useMemo(() => getCurrentSights(), [getCurrentSights]);

  return (
    <div className={styles['container']}>
      <div className={styles['content']}>
        <InspectionReport
          inspectionId={inspectionId}
          apiConfig={apiConfig}
          sights={currentSights}
        />
      </div>
    </div>
  );
}
