import { Styles } from '@monkvision/types';

export const styles: Styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  tabs: {
    display: 'flex',
    justifyContent: 'center',
  },
  exteriorButton: {
    color: 'black',
    width: '200px',
    borderWidth: '1px 0px 1px 1px',
    borderRadius: '99px 0px 0px 99px',
  },
  interiorButton: {
    color: 'black',
    width: '200px',
    borderWidth: '1px 1px 1px 1px',
    borderRadius: '0px 99px 99px 0px',
  },
  pdfButton: {
    borderRadius: '9px',
  },
  icon: {
    height: '20px',
  },
};
